<template>
  <div>
    <section class="section is-main-section">
      <h1 class="title">
        {{ title }} ({{ models.length }})
        <b-button
          slot="right"
          @click="add()"
          class="is-primary"
          icon-left="plus"
        >
          ເພີ່ມໃໝ່
        </b-button>
      </h1>
      <card-component class="has-table has-mobile-sort-spaced">
        <b-table
          :loading="isLoading"
          :paginated="perPage < models.length"
          :per-page="perPage"
          :striped="true"
          :hoverable="true"
          default-sort="province.name"
          :data="models"
          :current-page.sync="currentPage"
          :pagination-rounded="true"
        >
          <b-table-column label="#" v-slot="props">
            {{ props.index+(currentPage>1?(perPage*currentPage-perPage):0)+1 }}
          </b-table-column>
          <b-table-column label="ແຂວງ" field="name" sortable v-slot="props">
            {{ props.row.province.name }}
          </b-table-column>
          <b-table-column label="ຊື່ເມືອງ" field="name" sortable v-slot="props">
            {{ props.row.name }}
          </b-table-column>
          <b-table-column
            custom-key="actions"
            cell-class="is-actions-cell"
            v-slot="props"
          >
            <div class="buttons is-right">
              <b-button
                class="is-small is-warning"
                @click="edit(props.row)"
                icon-left="pencil"
              >
              </b-button>
              <b-button
                class="is-small is-danger"
                v-if="false"
                icon-left="trash-can"
              >
              </b-button>
            </div>
          </b-table-column>

          <empty-table v-if="models.length == 0" :isLoading="isLoading" />
        </b-table>
      </card-component>
    </section>

    <div class="modal is-active" v-if="isShowModal && model">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">{{ title }}</p>
          <button
            class="delete"
            aria-label="close"
            @click="closeModal()"
          ></button>
        </header>
        <section class="modal-card-body">
          <b-field label="ແຂວງ" horizontal>
            <b-select v-model="model.province" placeholder="ແຂວງ" required>
              <option
                v-for="(province, index) in provinces"
                :key="index"
                :value="province"
              >
                {{ province.name }}
              </option>
            </b-select>
          </b-field>

          <b-field label="ຊື່ເມືອງ" horizontal>
            <b-input v-model="model.name" placeholder="ເມືອງ" required />
          </b-field>
        </section>
        <footer class="modal-card-foot">
          <b-button
            class="is-success"
            slot="right"
            @click="save()"
            icon-left="check"
          >
            ບັນທຶກ
          </b-button>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import { get } from "vuex-pathify";
import CardComponent from "@/components/CardComponent";
import EmptyTable from "../components/EmptyTable.vue";
const route = "districts";
export default {
  name: "Districts",
  components: { CardComponent, EmptyTable },
  computed: {
    user: get("user"),
  },
  data() {
    return {
      currentPage: 1,
      perPage: 20,
      title: "ເມືອງ",
      model: null,
      models: [],
      isShowModal: false,
      isLoading: false,
      url: null,
      provinces: [],
    };
  },
  mounted() {
    Axios.get("provinces").then((res) => {
      this.provinces = res;
    });
    this.get();
  },
  methods: {
    get() {
      this.model = null;
      this.isLoading = true;
      Axios.get(route)
        .then((r) => {
          this.isLoading = false;
          this.models = r;
        })
        .catch((e) => {
          this.isLoading = false;
          console.error(e);
        });
    },
    add() {
      this.model = {};
      this.isShowModal = true;
    },
    edit(item) {
      this.model = item;
      this.isShowModal = true;
    },
    save() {
      let req;
      if (!this.model.id) {
        req = Axios.put(route, this.model);
      } else {
        req = Axios.post(route, this.model);
      }
      req.then((res) => {
        console.log(res);
        this.get();
      });
    },
    closeModal() {
      this.isShowModal = false;
    },
  },
};
</script>
